import React from 'react';

const FigmaDesign = ({ className }) => {
  return (
    <svg
      width="113"
      height="168"
      viewBox="0 0 113 168"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M28.2557 168C43.8466 168 56.5001 155.456 56.5001 140V112H28.2557C12.6648
        112 0.0113525 124.544 0.0113525 140C0.0113525 155.456 12.6648 168 28.2557 168Z"
        fill="#0EC878"
      />
      <path
        d="M0.0113525 84C0.0113525 68.544 12.6648 56 28.2557 56H56.5001V112H28.2557C12.6648
        112 0.0113525 99.4559 0.0113525 84Z"
        fill="#974FFF"
      />
      <path
        d="M0.012085 28C0.012085 12.544 12.6656 0 28.2564 0H56.5008V56H28.2564C12.6656
        56 0.012085 43.456 0.012085 28Z"
        fill="#F0441C"
      />
      <path
        d="M56.5 0H84.7444C100.335 0 112.989 12.544 112.989 28C112.989 43.456 100.335
         56 84.7444 56H56.5V0Z"
        fill="#F47055"
      />
      <path
        d="M112.989 84C112.989 99.4559 100.335 112 84.7444 112C69.1535 112 56.5 99.4559 56.5
         84C56.5 68.544 69.1535 56 84.7444 56C100.335 56 112.989 68.544 112.989 84Z"
        fill="#18B4FE"
      />
    </svg>
  );
};

export default FigmaDesign;
