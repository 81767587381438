import React from 'react';
import HatchSashThin from '../../../components/svg-illustrations/svgs/hatchings/HatchSashThin';
import Hexagon from '../../../components/svg-illustrations/svgs/shapes/Hexagon';

const DeviceIllustration = ({ role, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="304"
      height="192"
      viewBox="0 0 304 192">
      <Hexagon />
      <HatchSashThin />
      {role === 'code' ? (
        <g fill="none" fillRule="evenodd">
          <rect width="32" height="60" x="11" y="91" fill="#596775" rx="6" />
          <rect width="32" height="60" x="143" y="24" fill="#404D59" rx="6" />
          <rect width="74" height="52" x="141" y="91" fill="#65727F" rx="8" />
          <rect width="90" height="48" x="47" y="62" fill="#404D59" rx="8" />
          <rect width="72" height="60" x="219" y="51" fill="#353F4A" rx="8" />
          <rect width="26" height="54" x="14" y="94" fill="#101417" rx="4" />
          <rect width="28" height="46" x="145" y="29" fill="#101417" rx="4" />
          <rect width="4" height="4" x="157" y="77" fill="#101417" rx="2" />
          <rect width="4" height="4" x="143" y="115" fill="#101417" rx="2" />
          <rect width="58" height="48" x="150" y="93" fill="#101417" rx="8" />
          <rect width="84" height="41" x="50" y="66" fill="#101417" rx="8" />
          <rect width="66" height="53" x="222" y="55" fill="#101417" rx="8" />
          <rect width="22" height="49" x="16" y="97" fill="#FF6300" rx="2" />
          <rect width="16" height="4" x="19" y="95" fill="#101417" rx="2" />
          <rect width="14" height="5" x="20" y="92" fill="#596775" rx="2" />
          <rect width="24" height="42" x="147" y="31" fill="#FF6300" rx="2" />
          <rect width="54" height="44" x="152" y="95" fill="#FF6300" rx="7" />
          <rect width="80" height="37" x="52" y="68" fill="#FF6300" rx="6" />
          <rect width="62" height="49" x="224" y="57" fill="#FF6300" rx="7" />
          <path
            fill="#FF7A00"
            d="M20 101L34.2900435 101C35.394613 101 36.2900435 101.895431 36.2900435 103 36.2900435 103.514367
            36.0918685 104.00896 35.7366939 104.381015L21.4466504 119.35022C20.6839369 120.149183 19.4179479 120.178569
            18.618985 119.415856 18.2236619 119.038469 18 118.515741 18 117.969205L18 103C18 101.895431 18.8954305 101
            20 101zM151 33L165.290044 33C166.394613 33 167.290044 33.8954305 167.290044 35 167.290044 35.5143674
            167.091868 36.0089601 166.736694 36.381015L152.44665 51.3502202C151.683937 52.1491832 150.417948 52.1785692
            149.618985 51.4158556 149.223662 51.0384686 149 50.5157409 149 49.9692052L149 35C149 33.8954305 149.895431
            33 151 33zM160 97L181.903845 97C184.112984 97 185.903845 98.790861 185.903845 101 185.903845 102.081049
            185.466277 103.116067 184.690819 103.869281L160.786974 127.087429C159.202314 128.626633 156.669922
            128.589782 155.130719 127.005122 154.405609 126.258598 154 125.25886 154 124.218148L154 103C154 99.6862915
            156.686292 97 160 97zM60 70L82.9307738 70C85.1399128 70 86.9307738 71.790861 86.9307738 74 86.9307738
            75.0990816 86.478533 76.1497407 85.6802647 76.9052194L60.7494909 100.499628C59.1449825 102.01813 56.6132824
            101.948408 55.0947806 100.3439 54.3917668 99.6010679 54 98.6171637 54 97.5944089L54 76C54 72.6862915
            56.6862915 70 60 70zM232 59L261.857905 59C264.067044 59 265.857905 60.790861 265.857905 63 265.857905
            64.0690414 265.429976 65.0935997 264.669606 65.8450557L232.8117 97.3294591C231.240419 98.8823183 228.707804
            98.8673845 227.154944 97.2961037 226.414978 96.5473595 226 95.5370987 226 94.4844034L226 65C226 61.6862915
            228.686292 59 232 59z"
          />
          <rect width="72" height="60" x="219" y="113" fill="#353F4A" rx="8" />
          <rect width="90" height="48" x="47" y="112" fill="#404D59" rx="8" />
        </g>
      ) : (
        <g fill="none" fillRule="evenodd">
          <rect width="32" height="60" x="11" y="91" fill="#A5ADB5" rx="6" />
          <rect width="32" height="60" x="143" y="24" fill="#B7BFC7" rx="6" />
          <rect width="74" height="52" x="141" y="91" fill="#8C96A0" rx="8" />
          <rect width="90" height="48" x="47" y="62" fill="#B7BFC7" rx="8" />
          <rect width="72" height="60" x="219" y="51" fill="#DFE5EB" rx="8" />
          <rect width="26" height="54" x="14" y="94" fill="#FFF" rx="4" />
          <rect width="28" height="46" x="145" y="29" fill="#FFF" rx="4" />
          <rect width="4" height="4" x="157" y="77" fill="#FFF" rx="2" />
          <rect width="4" height="4" x="143" y="115" fill="#FFF" rx="2" />
          <rect width="58" height="48" x="150" y="93" fill="#FFF" rx="8" />
          <rect width="84" height="41" x="50" y="66" fill="#FFF" rx="8" />
          <rect width="66" height="53" x="222" y="55" fill="#FFF" rx="8" />
          <rect width="22" height="49" x="16" y="97" fill="#FF6300" rx="2" />
          <rect width="16" height="4" x="19" y="95" fill="#FFF" rx="2" />
          <rect width="14" height="5" x="20" y="92" fill="#A5ADB5" rx="2" />
          <rect width="24" height="42" x="147" y="31" fill="#FF6300" rx="2" />
          <rect width="54" height="44" x="152" y="95" fill="#FF6300" rx="7" />
          <rect width="80" height="37" x="52" y="68" fill="#FF6300" rx="6" />
          <rect width="62" height="49" x="224" y="57" fill="#FF6300" rx="7" />
          <rect width="72" height="60" x="219" y="113" fill="#DFE5EB" rx="8" />
          <rect width="90" height="48" x="47" y="112" fill="#B7BFC7" rx="8" />
          <path
            fill="#FF7A00"
            d="M20 101L34.2900435 101C35.394613 101 36.2900435 101.895431 36.2900435 103 36.2900435 103.514367
            36.0918685 104.00896 35.7366939 104.381015L21.4466504 119.35022C20.6839369 120.149183 19.4179479 120.178569
            18.618985 119.415856 18.2236619 119.038469 18 118.515741 18 117.969205L18 103C18 101.895431 18.8954305 101
            20 101zM151 33L165.290044 33C166.394613 33 167.290044 33.8954305 167.290044 35 167.290044 35.5143674
            167.091868 36.0089601 166.736694 36.381015L152.44665 51.3502202C151.683937 52.1491832 150.417948 52.1785692
            149.618985 51.4158556 149.223662 51.0384686 149 50.5157409 149 49.9692052L149 35C149 33.8954305 149.895431
            33 151 33zM160 97L181.903845 97C184.112984 97 185.903845 98.790861 185.903845 101 185.903845 102.081049
            185.466277 103.116067 184.690819 103.869281L160.786974 127.087429C159.202314 128.626633 156.669922
            128.589782 155.130719 127.005122 154.405609 126.258598 154 125.25886 154 124.218148L154 103C154 99.6862915
            156.686292 97 160 97zM60 70L82.9307738 70C85.1399128 70 86.9307738 71.790861 86.9307738 74 86.9307738
            75.0990816 86.478533 76.1497407 85.6802647 76.9052194L60.7494909 100.499628C59.1449825 102.01813 56.6132824
            101.948408 55.0947806 100.3439 54.3917668 99.6010679 54 98.6171637 54 97.5944089L54 76C54 72.6862915
            56.6862915 70 60 70zM232 59L261.857905 59C264.067044 59 265.857905 60.790861 265.857905 63 265.857905
            64.0690414 265.429976 65.0935997 264.669606 65.8450557L232.8117 97.3294591C231.240419 98.8823183 228.707804
            98.8673845 227.154944 97.2961037 226.414978 96.5473595 226 95.5370987 226 94.4844034L226 65C226 61.6862915
            228.686292 59 232 59z"
          />
        </g>
      )}
    </svg>
  );
};

export default DeviceIllustration;
