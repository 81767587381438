import React from 'react';

const NumberThree = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="49"
      height="55"
      viewBox="0 0 49 55">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EDF0F2"
          d="M26.999.67l19.5 11.25A5 5 0 0 1 49 16.25v22.497a5 5 0 0 1-2.501 4.33l-19.5 11.251a5 5 0 0 1-4.998
          0l-19.5-11.25A5 5 0 0 1 0 38.747V16.25a5 5 0 0 1 2.501-4.331L22.001.67A5 5 0 0 1 27 .67z"
        />
        <path
          fill="#13293F"
          d="M24.872 14.432c1.776 0 3.3.306 4.572.918 1.272.612 2.238 1.452 2.898 2.52.66 1.068.99 2.274.99 3.618 0
          1.656-.426 2.988-1.278 3.996-.852 1.008-1.914 1.644-3.186 1.908v.18c3.288 1.056 4.932 3.108 4.932 6.156 0
          1.464-.336 2.766-1.008 3.906-.672 1.14-1.65 2.028-2.934 2.664-1.284.636-2.826.954-4.626.954-2.928
          0-5.25-.72-6.966-2.16-1.716-1.44-2.646-3.636-2.79-6.588h6.012c.048 1.128.36 2.016.936 2.664.576.648 1.416.972
          2.52.972.888 0 1.59-.264 2.106-.792.516-.528.774-1.212.774-2.052
          0-2.088-1.512-3.132-4.536-3.132h-1.152V25.16h1.116c2.712.048 4.068-.924 4.068-2.916
          0-.864-.234-1.53-.702-1.998-.468-.468-1.098-.702-1.89-.702-.864 0-1.548.294-2.052.882-.504.588-.792
          1.374-.864 2.358H15.8c.12-2.688.96-4.752 2.52-6.192 1.56-1.44 3.744-2.16 6.552-2.16z"
        />
      </g>
    </svg>
  );
};

export default NumberThree;
