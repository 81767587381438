import React from 'react';

const DesigningWithUniform = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 960 138">
      <path
        fill="#13293F"
        fillOpacity=".8"
        fillRule="evenodd"
        d="M83.765,38.86 C99.375,38.86 110.645,48.8 110.645,63.43 C110.645,77.99 99.375,88 83.765,88 L66.475,88
        L66.475,38.86 L83.765,38.86 Z M78.445,78.9 L83.765,78.9 C93.005,78.9 98.465,72.81 98.465,63.43 C98.465,54.05
        93.005,47.96 83.765,47.96 L78.445,47.96 L78.445,78.9 Z M125.905,64.9 L140.535,64.9 C140.185,61.19 137.035,58.74
        132.975,58.74 C129.405,58.74 126.535,61.26 125.905,64.9 Z M138.785,75.4 L151.735,75.4 C149.565,83.1
        142.425,88.49 133.045,88.49 C121.775,88.49 113.585,80.72 113.585,68.4 C113.585,56.08 121.775,48.52
        132.975,48.52 C144.175,48.52 152.995,55.66 152.995,68.61 C152.995,69.8 152.925,71.06 152.715,72.39
        L125.835,72.39 C126.325,75.75 128.985,78.27 133.045,78.27 C136.405,78.27 137.455,77.01 138.785,75.4 Z
        M156.285,60.7 C156.285,54.19 161.955,48.52 173.155,48.52 C182.255,48.52 189.255,53.28 189.675,61.33
        L177.425,61.33 C177.145,58.53 175.465,57.83 172.455,57.83 C169.515,57.83 167.765,58.81 167.765,60.7
        C167.765,66.72 189.815,62.17 189.815,76.1 C189.815,83.52 182.675,88.49 173.435,88.49 C164.195,88.49
        156.845,84.5 156.215,75.61 L168.465,75.61 C168.815,78.27 170.705,79.18 173.505,79.18 C176.305,79.18
        178.335,77.71 178.335,75.82 C178.335,69.66 156.285,74.21 156.285,60.7 Z M196.045,88 L196.045,48.94
        L208.015,48.94 L208.015,88 L196.045,88 Z M201.995,46.56 C198.075,46.56 194.995,43.48 194.995,39.56
        C194.995,35.57 198.075,32.49 201.995,32.49 C205.915,32.49 208.995,35.57 208.995,39.56 C208.995,43.48
        205.915,46.56 201.995,46.56 Z M243.155,68.47 C243.155,61.89 238.675,58.88 234.615,58.88 C230.555,58.88
        226.075,61.96 226.075,68.54 C226.075,75.12 230.555,78.13 234.615,78.13 C238.675,78.13 243.155,75.05
        243.155,68.47 Z M213.895,68.54 C213.895,56.64 221.945,48.52 231.675,48.52 C236.785,48.52 240.635,50.55
        243.155,54.05 L243.155,48.94 L255.125,48.94 L255.125,87.79 C255.125,98.29 249.665,107.32 235.525,107.32
        C223.905,107.32 216.345,102.77 215.365,92.55 L227.125,92.55 C228.315,95.63 231.255,96.96 235.525,96.96
        C239.515,96.96 243.155,94.58 243.155,87.79 L243.155,82.33 C240.495,86.11 236.785,88.49 231.675,88.49
        C221.945,88.49 213.895,80.44 213.895,68.54 Z M275.845,48.94 L275.845,54.68 C278.085,50.97 282.215,48.52
        287.675,48.52 C296.425,48.52 302.725,54.68 302.725,65.18 L302.725,88 L290.825,88 L290.825,66.79 C290.825,61.68
        287.885,58.88 283.335,58.88 C278.785,58.88 275.845,61.68 275.845,66.79 L275.845,88 L263.875,88 L263.875,48.94
        L275.845,48.94 Z M311.125,88 L311.125,48.94 L323.095,48.94 L323.095,88 L311.125,88 Z M317.075,46.56
        C313.155,46.56 310.075,43.48 310.075,39.56 C310.075,35.57 313.155,32.49 317.075,32.49 C320.995,32.49
        324.075,35.57 324.075,39.56 C324.075,43.48 320.995,46.56 317.075,46.56 Z M343.815,48.94 L343.815,54.68
        C346.055,50.97 350.185,48.52 355.645,48.52 C364.395,48.52 370.695,54.68 370.695,65.18 L370.695,88 L358.795,88
        L358.795,66.79 C358.795,61.68 355.855,58.88 351.305,58.88 C346.755,58.88 343.815,61.68 343.815,66.79
        L343.815,88 L331.845,88 L331.845,48.94 L343.815,48.94 Z M405.485,68.47 C405.485,61.89 401.005,58.88
        396.945,58.88 C392.885,58.88 388.405,61.96 388.405,68.54 C388.405,75.12 392.885,78.13 396.945,78.13
        C401.005,78.13 405.485,75.05 405.485,68.47 Z M376.225,68.54 C376.225,56.64 384.275,48.52 394.005,48.52
        C399.115,48.52 402.965,50.55 405.485,54.05 L405.485,48.94 L417.455,48.94 L417.455,87.79 C417.455,98.29
        411.995,107.32 397.855,107.32 C386.235,107.32 378.675,102.77 377.695,92.55 L389.455,92.55 C390.645,95.63
        393.585,96.96 397.855,96.96 C401.845,96.96 405.485,94.58 405.485,87.79 L405.485,82.33 C402.825,86.11
        399.115,88.49 394.005,88.49 C384.275,88.49 376.225,80.44 376.225,68.54 Z M489.345,48.94 L500.615,48.94
        L488.925,88 L476.045,88 L470.585,64.9 L465.055,88 L452.175,88 L440.485,48.94 L452.455,48.94 L458.545,76.38
        L464.565,48.94 L477.235,48.94 L483.255,76.38 L489.345,48.94 Z M504.325,88 L504.325,48.94 L516.295,48.94
        L516.295,88 L504.325,88 Z M510.275,46.56 C506.355,46.56 503.275,43.48 503.275,39.56 C503.275,35.57
        506.355,32.49 510.275,32.49 C514.195,32.49 517.275,35.57 517.275,39.56 C517.275,43.48 514.195,46.56
        510.275,46.56 Z M541.635,77.85 L545.905,77.85 L545.905,88 L539.815,88 C531.695,88 526.025,85.06 526.025,75.12
        L526.025,58.88 L522.385,58.88 L522.385,48.94 L526.025,48.94 L526.025,39.42 L537.995,39.42 L537.995,48.94
        L545.835,48.94 L545.835,58.88 L537.995,58.88 L537.995,75.12 C537.995,76.94 538.975,77.85 541.635,77.85 Z
        M564.315,36.2 L564.315,54.68 C566.555,50.97 570.685,48.52 576.145,48.52 C584.895,48.52 591.195,54.68
        591.195,65.18 L591.195,88 L579.295,88 L579.295,66.79 C579.295,61.68 576.355,58.88 571.805,58.88 C567.255,58.88
        564.315,61.68 564.315,66.79 L564.315,88 L552.345,88 L552.345,36.2 L564.315,36.2 Z M618.635,68.19 L618.635,38.86
        L630.605,38.86 L630.605,68.19 C630.605,74 633.475,77.36 639.145,77.36 C644.815,77.36 647.825,74 647.825,68.19
        L647.825,38.86 L659.795,38.86 L659.795,68.19 C659.795,81.77 649.505,88.49 638.935,88.49 C628.295,88.49
        618.635,81.77 618.635,68.19 Z M680.235,48.94 L680.235,54.68 C682.475,50.97 686.605,48.52 692.065,48.52
        C700.815,48.52 707.115,54.68 707.115,65.18 L707.115,88 L695.215,88 L695.215,66.79 C695.215,61.68 692.275,58.88
        687.725,58.88 C683.175,58.88 680.235,61.68 680.235,66.79 L680.235,88 L668.265,88 L668.265,48.94 L680.235,48.94
        Z M715.515,88 L715.515,48.94 L727.485,48.94 L727.485,88 L715.515,88 Z M721.465,46.56 C717.545,46.56
        714.465,43.48 714.465,39.56 C714.465,35.57 717.545,32.49 721.465,32.49 C725.385,32.49 728.465,35.57
        728.465,39.56 C728.465,43.48 725.385,46.56 721.465,46.56 Z M753.315,48.94 L753.315,58.88 L748.345,58.88
        L748.345,88 L736.375,88 L736.375,58.88 L732.735,58.88 L732.735,48.94 L736.375,48.94 L736.375,47.47
        C736.375,37.53 741.555,33.19 752.755,33.19 L752.755,43.34 C749.605,43.34 748.345,44.32 748.345,47.47
        L748.345,48.94 L753.315,48.94 Z M755.205,68.4 C755.205,56.08 764.375,48.52 775.645,48.52 C786.845,48.52
        796.015,56.08 796.015,68.4 C796.015,80.72 786.985,88.49 775.715,88.49 C764.445,88.49 755.205,80.72 755.205,68.4
        Z M783.835,68.47 C783.835,61.68 779.635,58.74 775.645,58.74 C771.515,58.74 767.385,61.68 767.385,68.47
        C767.385,75.26 771.655,78.27 775.715,78.27 C779.775,78.27 783.835,75.26 783.835,68.47 Z M813.935,48.94
        L813.935,56.22 C816.455,51.6 820.305,48.52 825.135,48.52 L825.135,60.7 L822.195,60.7 C816.945,60.7
        813.935,62.73 813.935,69.52 L813.935,88 L801.965,88 L801.965,48.94 L813.935,48.94 Z M841.865,48.94
        L841.865,54.68 C844.105,50.97 848.235,48.52 853.695,48.52 C859.505,48.52 864.195,51.25 866.715,56.08
        C869.445,51.74 874.135,48.52 880.085,48.52 C889.535,48.52 895.625,54.68 895.625,65.18 L895.625,88 L883.725,88
        L883.725,66.79 C883.725,61.68 880.785,58.88 876.235,58.88 C871.685,58.88 868.745,61.68 868.745,66.79
        L868.745,88 L856.845,88 L856.845,66.79 C856.845,61.68 853.905,58.88 849.355,58.88 C844.805,58.88 841.865,61.68
        841.865,66.79 L841.865,88 L829.895,88 L829.895,48.94 L841.865,48.94 Z"
      />
    </svg>
  );
};

export default DesigningWithUniform;
