import React from 'react';

const NumberTwo = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="49"
      height="55"
      viewBox="0 0 49 55">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EDF0F2"
          d="M26.999.67l19.5 11.25A5 5 0 0 1 49 16.25v22.497a5 5 0 0 1-2.501 4.33l-19.5 11.251a5 5 0 0 1-4.998
          0l-19.5-11.25A5 5 0 0 1 0 38.747V16.25a5 5 0 0 1 2.501-4.331L22.001.67A5 5 0 0 1 27 .67z"
        />
        <path
          fill="#13293F"
          d="M15.224 34.104c7.632-5.448 11.448-9.78 11.448-12.996 0-1.968-.852-2.952-2.556-2.952-.96 0-1.662.336-2.106
          1.008-.444.672-.69 1.716-.738 3.132H15.26c.144-3.096 1.08-5.424 2.808-6.984 1.728-1.56 3.912-2.34 6.552-2.34
          2.76 0 4.842.696 6.246 2.088 1.404 1.392 2.106 3.204 2.106 5.436 0 2.472-.948 4.89-2.844 7.254a26.52 26.52 0
          0 1-6.804 5.994h10.044v4.968H15.224v-4.608z"
        />
      </g>
    </svg>
  );
};

export default NumberTwo;
