import React from 'react';

const NumberOne = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="49"
      height="55"
      viewBox="0 0 49 55">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EDF0F2"
          d="M26.999.67l19.5 11.251A5 5 0 0 1 49 16.252v22.496a5 5 0 0 1-2.501 4.33l-19.5 11.252a5 5 0 0 1-4.998
          0l-19.5-11.251A5 5 0 0 1 0 38.748V16.252a5 5 0 0 1 2.501-4.33L22.001.67A5 5 0 0 1 27 .67z"
        />
        <polygon
          fill="#13293F"
          points="17.396 20.308 17.396 14.548 27.692 14.548 27.692 40 21.212 40 21.212 20.308"
        />
      </g>
    </svg>
  );
};

export default NumberOne;
